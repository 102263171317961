<template>
    <div>
        <!--New Claim-->
        <b-modal title="New Claim" class="modal-dark" v-model="claimModal">
            <label> Claim</label>
            <b-form-group>
                <b-form-input v-model="newClaim.claim" type="text"></b-form-input>
                <label> Description</label>
                <b-form-input v-model="newClaim.description" type="text"></b-form-input>
            </b-form-group>
            <template slot="modal-footer">
                <b-button size="sm" variant="secondary" @click="claimModal=false">Cancel</b-button>
                <b-button size="sm" variant="primary" @click="CreateAUserClaimRequest">submit</b-button>
            </template>
        </b-modal>

        <!--Edit Claim-->
        <b-modal title="Edit Claim" class="modal-dark" v-model="editModal">
            <label> Edit Claim</label>
            <b-form-group>
                <b-form-input v-model="edittedClaim.claim" type="text"></b-form-input>
            </b-form-group>
            <label> Edit Description</label>
            <b-form-group>
                <b-form-input v-model="edittedClaim.description" type="text"></b-form-input>
            </b-form-group>
            <template slot="modal-footer">
                <b-button size="sm" variant="secondary" @click="editModal=false">Cancel</b-button>
                <b-button size="sm" variant="primary" @click="PutClaimRequest">Submit</b-button>
            </template>
        </b-modal>

        <!-- Delete Claim -->
        <b-modal :hide-footer="true" title="Delete Claim" class="modal-dark" v-model="deleteModal">
            <b-form-group>
                <label>Are you sure you want to delete this claim?</label>
                <b-button style="margin-right: 0.5em" variant="secondary" @click="deleteModal=false">No</b-button>
                <b-button variant="danger" @click="deleteClaim">Yes</b-button>
            </b-form-group>
        </b-modal>

        <!--Claim Header-->
        <b-card header-tag="header">

            <!--Header-->
            <span slot="header" class="d-flex w-100 justify-content-between align-content-center">
                <h5 style="margin: 0"><b>Claims</b></h5>
                <fa-icon :icon="['fas', 'plus']" @click="openAddClaim()"/>
            </span>

            <!--Request Table-->
            <v-client-table ref="dTable" style="width: auto" :columns="columns" :data="Claim" :options="tOptions"
                            class="dataTable">
                <div slot="edit" slot-scope="props" style="float: right">
                    <fa-icon :icon="['fas', 'pencil']" style="margin-right: 10px" @click="openEditUserClaim(props.row)"/>
                    <fa-icon :icon="['fas', 'trash']" @click="openDeleteClaim(props.row)"/>
                </div>
            </v-client-table>

        </b-card>

    </div>
</template>

<script>
import Iss from '../../../services/iss.js'
import Vue from 'vue'
import {ClientTable} from 'vue-tables-2';

Vue.use(ClientTable)

export default {
    name: "Claims",

    data: () => {
        return {
            columns: ['claim', 'description', 'edit'],
            tOptions: {
                sortable: ['claim', 'description', ''],
                filterable: ['claim', 'description'],
                orderBy: {column: 'claim', ascending: true},
                sortIcon: {base: 'fas', up: 'fa-sort-up', down: 'fa-sort-down', is: 'fa-sort'},
                headings: {
                    claim: 'Claim',
                    description: 'Description',
                },
                pagination: {
                    chunk: 5,
                    edge: false,
                    nav: 'scroll'
                },
                perPage: 25,
                skin: 'table table-striped table-hover',
            },
            Claim: [],
            edittedClaim: {},
            toDelete: {},
            newClaim: {
                claim: '',
                description: '',
            },
            claimModal: false,
            editModal: false,
            deleteModal: false
        }
    },

    created() {
        this.GetClaims();
    },

    methods: {
        GetClaims() {
            Iss.getClaims().then(response => {
                this.Claim = response.data;
            })
        },
        CreateAUserClaimRequest() { //need single user not all users
            Iss.postClaims(this.newClaim).then(() => {
            })
            this.claimModal = false
            this.newClaim = {
                claim: '',
                description: '',
            };
            this.GetClaims();
        },
        PutClaimRequest() {
            Iss.putUpdateClaims(this.edittedClaim).then(() => {
                this.edittedClaim = {}
                this.GetClaims();
            })
            this.editModal = false
            this.edittedClaim = '';
        },
        deleteClaim() {
            Iss.deleteClaim(this.toDelete._id).then(() => {
                this.toDelete = {};
                this.GetClaims();
                this.$mToast({
                    title: 'Deleted Claim',
                    text: 'Successfully deleted claim.',
                    style: 'success'
                });
            });
            this.deleteModal = false;
        },
        openAddClaim() {
            this.claimModal = true
        },
        openEditUserClaim(item) {
            this.editModal = true
            this.edittedClaim = JSON.parse(JSON.stringify(item))
        },
        openDeleteClaim(item) {
            this.deleteModal = true;
            this.toDelete = JSON.parse(JSON.stringify(item));
        },
        dateify(time) {
            let created_date = time;
            let date = [];
            let months = ["", "January", "February", "March", "April", "May", "June", "July", "August", "September", "October", "November", "December"];
            date.push(created_date.substring(0, 4));
            date.push(created_date.substring(5, 7));
            date.push(created_date.substring(8, 10));
            date.push(created_date.substring(11, 16));
            let mlong = months[parseInt(date[1])];

            function tConvert(time) {
                time = time.toString().match(/^([01]\d|2[0-3])(:)([0-5]\d)(:[0-5]\d)?$/) || [time];
                if (time.length > 1) {
                    time = time.slice(1);
                    time[5] = +time[0] < 12 ? 'AM' : 'PM';
                    time[0] = +time[0] % 12 || 12;
                }
                return time.join('');
            }

            date[3] = tConvert(date[3]);
            return (mlong + " " + date[2] + ", " + date[0] + " at " + date[3] + " UTC");
        },
    },
}
</script>

<style scoped>

</style>